import './App.css';
import HomePage from './pages/HomePage';


function App() {

  return (
    <div className="App">
      <HomePage></HomePage>
    </div>
  );
}

export default App;